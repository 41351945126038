<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav class="left-nav">
        <div class="items">
          <button
            v-for="event in events"
            :key="event.id"
            class="btn w-100 text-start"
            v-on:click="activeEvent = event"
            :class="event.id === activeEvent?.id ? 'btn-primary' : 'btn-simple'"
          >
            {{ event.name }}
          </button>
        </div>
      </nav>
    </div>
    <div
      v-if="activeEvent"
      class="
        col-md-8 col-lg-10
        d-flex
        align-items-center
        flex-column
        custom-input
      "
    >
      <div class="row align-self-start">
        <div
            class="col-auto p-0 m-0 mb-3"
            v-for="l in languages"
            :key="l.code"
        >
          <button
              class="btn btn-tab"
              :class="{ active: l.code === selectedLanguage }"
              v-on:click="selectedLanguage = l.code"
          >
            <span class="semibold-14">{{ l.name }}</span>
          </button>
        </div>
      </div>
      <div class="event">
        <b>{{ activeEvent.name }}</b>
      </div>
      <div
        v-for="action in activeEvent.eventActions.filter((e) => e.language_code === selectedLanguage)"
        :key="action.id"
        class="d-flex flex-column align-items-center"
      >
        <div class="event-line">
          <!--<span class="event-line-time">
            Wait<br /><input
              type="number"
              v-model="action.delay"
              min="0"
              class="input-in-text"
            />
            minutes
          </span>-->
        </div>
        <div class="action" style="min-width: 750px;">
          <div class="row custom-input whitebg-input mb-3">
            <div class="col-4">
              <select v-model="action.app_id" class="form-control">
                <option v-for="app in apps" :key="app.id" :value="app.app_id">
                  {{ app.name }}
                </option>
              </select>
            </div>
            <div class="col">
              <input
                class="form-control"
                type="text"
                v-model="action.title"
                :placeholder="$t(`events.subject`)"
              />
            </div>
          </div>
          <textarea v-model="action.text" style="width: 100%;height:550px;background: #fff!important;padding:0.3em!important;"></textarea>
          <!--<WYSIWYG :hints="hints" v-model="action.html" /> -->
          <!-- <p style="text-align: center; "><img style="width: 394.688px; height: 44.6168px;" src="https://i.imgur.com/0w6Bj1J.png"></p><p class=""><br></p><p class=""><br></p><h2 class="">Köszönjük megrendelésedet</h2><div><div><b>Kedves {firstname}!</b></div><div>Megrendelésedet megkaptuk, annak feldolgozását megkezdtük.</div><div><br></div><div><br></div></div><div><table><tbody><tr><td>Rendelés</td><td>Szállítás</td><td>Fizetés</td></tr><tr><td>#347655</td><td>Házhozszállítás</td><td>Online bankkártya</td></tr><tr><td>2021. december 14.</td><td>8600, Veszprém Aradi tér 34.</td><td>Visa -3452<br></td></tr></tbody></table></div> -->
          <div class="mt-2">
            <button v-on:click="convert(action)" class="btn btn-primary">
              {{ $t("finance.save") }}
            </button>
          </div>
          <div class="mt-3">
            <div>
              <b>{{ $t("events.variables") }}</b>
            </div>
            <span
              v-for="variable in activeEvent.EventVariables"
              :key="variable.id"
              class="mt-1 mx-1 d-inline-block"
            >
              <span class="badge badge-outline">{{ variable.variable }}</span>
              {{ variable.description }}
            </span>
          </div>
        </div>
      </div>
      <div class="event-line"></div>
      <button class="btn btn-type-2" v-on:click="addNew">
        <span>{{ $t("companies.addNew") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
//import WYSIWYG from "@/components/inputs/WYSIWYG";
import http from "../../modules/http";
import {useStore} from "vuex";
//const TurndownService = require("turndown").default;
//const turndownPluginGfm = require("turndown-plugin-gfm");
const MarkdownIt = require("markdown-it");

export default {
  name: "Events.vue",
  //components: { WYSIWYG },
  data() {
    let _this = this;
    return {
      store: useStore(),
      selectedLanguage: "en",
      activeEvent: null,
      turndownService: null,
      text: "",
      events: [],
      apps: [],
      hints: {
        match: /\{\{(\w{1,})$/,
        search: function (keyword, callback) {
          callback(_this.words.filter((item) => item.indexOf(keyword) === 0));
        },
        content: function (item) {
          return "{{" + item + "}}";
        },
      },
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    languages() {
      return this.store.state.languages;
    },
    words() {
      if (this.activeEvent === null) {
        return [];
      }
      return this.activeEvent.EventVariables.map((e) => "" + e.variable + "");
    },
  },
  watch: {
    activeEvent() {
      for (const key in this.activeEvent.eventActions) {
        this.activeEvent.eventActions[key].html = this.convertToHtml(
          this.activeEvent.eventActions[key].text
        );
      }
    },
  },
  methods: {
    convert(action) {
      /*
      const gfm = turndownPluginGfm.gfm;
      const turndownService = new TurndownService();
      turndownService.use(gfm);
      turndownService.addRule("color", {
        filter: ["font"],
        replacement: function (content, node) {
          return `::: color ${node.color}\n${content}\n:::\n`;
        },
      });

      turndownService.addRule("justify", {
        filter: function (node) {
          return (
            node.style.textAlign !== undefined &&
            node.style.textAlign !== null &&
            node.style.textAlign.length > 0 &&
            node.style.textAlign !== "left"
          );
        },
        replacement: function (content, node) {
          return `::: justify ${node.style.textAlign}\n${content}\n:::\n`;
        },
      });

      let text = action.html
        .replaceAll("<tbody", "<thead")
        .replaceAll("</tbody", "</thead")
        .replaceAll("<br></td>", "</td>");

      text = text
        .replaceAll("</thead>\n" + "</table>", "</tbody></table>")
        .replace("</thead>\n" + "<thead>", "</thead><tbody>")
        .replaceAll("</thead>\n" + "<thead>", "");

      let markdown = turndownService.turndown(text);
*/
      let data = {
        //text: markdown,
        text: action.text,
        title: action.title,
        delay: action.delay,
        app_id: action.app_id,
        language_code: this.selectedLanguage,
      };
      //action.text = markdown;
      if (action.id !== undefined) {
        http.fetch("/events/" + action.id, data, true, "PUT");
      } else {
        data["event_id"] = this.activeEvent.id;
        http.fetch("/events", data, true).then((data) => {
          action.id = data.id;
        });
      }
    },

    convertToHtml(markdown) {
      const md = new MarkdownIt({ breaks: true });
      md.use(require("markdown-it-container"), "justify", {
        render: function (tokens, idx) {
          if (tokens[idx].nesting === 1) {
            var m = tokens[idx].info.trim().match(/^justify\s+(.*)$/);
            // opening tag
            return '<div style="text-align:' + m[1] + ';">';
          } else {
            return "</div>";
          }
        },
      });

      md.use(require("markdown-it-container"), "color", {
        validate: function (params) {
          return params.trim().match(/^color\s+(.*)$/);
        },
        render: function (tokens, idx) {
          if (tokens[idx].nesting === 1) {
            var m = tokens[idx].info.trim().match(/^color\s+(.*)$/);
            // opening tag
            return '<font color="' + m[1] + '">';
          } else {
            return "</font>";
          }
        },
      });

      let html = md.render(markdown);

      return html;
    },
    loadData() {
      http.fetch("/events").then((data) => {
        this.events = data.events;
        this.apps = data.apps;
        this.activeEvent = this.events[0];
      });
    },
    addNew() {
      this.activeEvent.eventActions.push({
        app_id: this.apps[0].app_id,
        delay: 0,
        event_id: this.activeEvent.id,
        template: null,
        text: "",
        title: "",
        language_code: this.selectedLanguage,
      });
    },
  },
};
</script>
<style>
.event,
.action {
  background: #f7f8ff;
  display: inline-block;
  padding: 1em 2em;
  border-radius: 10px;
  box-shadow: 0 0 1px 1px #ebecf3;
}

.action {
  background: #f1f1f1;
  box-shadow: 0 0 1px 1px #e4e1e1;
}

.event-line {
  border-left: 1px solid #ebecf3;
  height: 5em;
  width: 1px;
  margin: 0.4em;
  position: relative;
}
.event-line span {
  position: absolute;
  width: 100px;
  text-align: center;
  margin-left: -50px;
  background: #fff;
  margin-top: 1em;
}
</style>
